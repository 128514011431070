<template>
  <div>
    <slot :status="moduleStatus" />
    <b-card v-if="!moduleStatus">
      <b-row class="mb-2 justify-content-end">
        <b-col
          v-if="searchBox"
          cols="6"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            placeholder="Search..."
            @keyup.enter="searchTable"
          />
        </b-col>

        <bulk-actions
          v-if="selectable"
          ref="actions"
          :api-url="apiUrl"
          :add-type="addType"
          :bulk-delete="bulkDelete"
          :go-to-page-or-open-modal="goToPageOrOpenModal"
          :guard="guard"
          :selected="selected"
        >
          <template v-slot:addbtn>
            <b-button
              v-if="addType.length"
              variant="primary"
              @click="goToPageOrOpenModal()"
            >
              <span class="text-nowrap"> {{ addType }} </span>
            </b-button>
          </template>
        </bulk-actions>
        <b-button
          v-if="!selectable && addType.length"
          ref="actions"
          variant="primary"
          class="ml-auto mr-1"
          @click="goToPageOrOpenModal()"
        >
          <span class="text-nowrap"> {{ addType }} </span>
        </b-button>

      </b-row>
      <b-row class="mb-2">
        <b-col
          v-if="selectcertificate"
          cols="6"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="showNameCertificate"
            class="d-inline-block w-100"
            :options="certificateOptions"
            placeholder="Select By Certificate"
            label="text"
            :searchable="true"
            :clearable="true"
            @input="searchCertificateTable"
            @clear="clearCertificateSelection"
          />
        </b-col>
        <b-col md="2" />
        <b-col
          v-if="selectSubcertificate"
          cols="6"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="showNameSubCertificate"
            class="d-inline-block w-100"
            :options="localSubCertificateOptions"
            placeholder="Select By Sub Certificate"
            label="text"
            :searchable="true"
            :clearable="true"
            @input="searchSubCertificateTable"
            @clear="clearSubCertificateSelection"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <div
            v-if="dataLoad"
            class=" text-center my-2"
          >
            <b-spinner
              type="grow"
              label="Loading..."
            />
          </div>
          <b-table
            v-else
            ref="productsTable"
            :key="tableDataKey"
            :items="rows"
            :fields="columns"
            :current-page="currentPage"
            :per-page="perPage"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
            :selectable="selectable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :select-mode="selectMode"
            :no-local-sorting="true"
            :post-content="PostContent"
            @row-selected="onRowSelected"
          >
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot
                :name="slotName"
                v-bind="scope"
              />
            </template>
            <template v-slot:cell(created_at)="value">
              {{ value.item.created_at | formatDate }}
            </template>
            <template v-slot:cell(avatar_url)="value">
              <b-avatar
                size="50"
                :src="value.item.avatar_url"
                class="mb-2"
              />
            </template>
            <template v-slot:cell(image)="value">
              <b-img
                width="70"
                :src="value.item.image"
              /></template>
            <template v-slot:cell(desc_en)="value">
              <div class="desTrim">
                {{ value.item.desc_en }}
              </div>
            </template>
            <template v-slot:cell(desc_ar)="value">
              <div class="desTrim">
                {{ value.item.desc_ar }}
              </div>
            </template>

            <template #cell(actions)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div class="padd">
                  <span
                    v-if="PostContent"
                    title="Post"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="cursor-pointer"
                      @click="PostEntry(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="PostContent"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'PostEntry',
                          params: { id: data.item.id }
                        })
                      "
                    />
                  </span>
                  <span
                    v-if="viewContent"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="viewPage(data.item)"
                    />
                  </span>
                  <span
                    v-if="editContent"
                    title="Edit"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer"
                      @click="editToPage(data.item.id , getName(data.item.name) || data.item.name_en)"
                    />
                  </span>
                  <span
                    v-if="blockContent && data.item.status != 'Blocked'"
                    title="Block"
                  >
                    <feather-icon
                      icon="UserMinusIcon"
                      class="cursor-pointer"
                      @click="viewmodel(data.item.id, 1)"
                    />
                  </span>
                  <span
                    v-if="data.item.status == 'Blocked'"
                    title="UnBlock"
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      class="cursor-pointer"
                      @click="viewmodel(data.item.id, 0)"
                    />
                  </span>
                  <span
                    v-if="deleteContent"
                    title="Delete"
                  >
                    <feather-icon
                      id="popover-button-1"
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="deleteRow(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="CancelContent"
                    title="Cancel"
                  >
                    <feather-icon
                      icon="SlashIcon"
                      class="cursor-pointer"
                      @click="CancelEntry(data.item.id)"
                    />
                  </span>
                </div>
              </slot>

            </template>

            <template v-slot:cell(QRCode)="value">
              <QrCode
                v-if="value.item.role !== 'delegator'"
                :item-id="value.item.token"
                :item-name="value.item.name"
              />
            </template>

            <template #cell(info)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="showInfo(data.item.department)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template v-slot:cell(QRCodeDevice)="value">
              <b-media vertical-align="center">
                <span v-if="value.item.status === 'offline'" />
                <QRCodeDevice
                  v-else
                  :style="`margin:'10px 0px'`"
                  :item-id="value.item.unique_id"
                  :item-name="value.item.name"
                  :item-serial-number="value.item.gulftic_device_id"
                />
              </b-media>
            </template>

            <template #cell(actions-view)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'certificate',
                          params: { MCR_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(sections)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'sections',
                          params: { SE_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>
            <template #cell(components)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'components',
                          params: { CM_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(requirements)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'requirements',
                          params: { RQ_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(company)="data">
              <slot
                name="company"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="showCompany(data.item.company)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(showCertificationCompanies)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'representatives',
                          params: { R_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>
            <!--
            <template #cell(showTechnicalExperts)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'technical-experts',
                          params: { T_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(product)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'show-product',
                          params: { P_id: data.item.product.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template> -->

            <template #cell(request_histories)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'request-histories',
                          params: { RH_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(assign-manager)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <b-button
                      style="background-color:#297dd6 !important ; border:1px solid #297dd6 !important ;"
                      @click="
                        $router.push({
                          name: 'assign_manager',
                          params: { RAM_id: data.item.id }
                        })
                      "
                    >Assign Manager</b-button>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(assign-reviewer)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <b-button
                      style="background-color:#297dd6 !important ; border:1px solid #297dd6 !important ;"
                      @click="
                        $router.push({
                          name: 'assign_reviewer',
                          params: { RAR_id: data.item.id }
                        })
                      "
                    >Assign Reviewer</b-button>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(file)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="Download">
                    <a
                      :href="data.item.file.url"
                      target="_blank"
                    >
                      <feather-icon
                        icon="DownloadCloudIcon"
                        class="cursor-pointer"
                      />
                    </a>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(approved)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div
                  style="text-align: center;"
                  :style="
                    `color: ${
                      data.item.status === 'offline' ? 'red' : ''
                    }; padding: ${
                      data.item.status === 'offline' ? '5px 0px' : ''
                    };`
                  "
                >
                  <b-button
                    v-if="data.item.approved === 0"
                    variant="dark"
                    style="font-size: 10px;"
                    @click="ApprovalDevies(data.item.id)"
                  >
                    Need Approval</b-button>
                  <span
                    v-if="data.item.approved === 1"
                    title="Success"
                    style="color: blue; "
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="30"
                      style="cursor: pointer;"
                      @click="ApprovalDevies(data.item.id)"
                    />
                  </span>
                </div>
              </slot>
            </template>
            <template #cell(name)="data">
              <span style="font-size: smaller; text-wrap: nowrap">
                {{ data.item.name }}
              </span>
            </template>

            <template #cell(is_active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.is_active === true ? 'success' : 'danger'"
                @click="changeSchangeActivetatus(data.item)"
              >
                {{ resolveActiveUser(data.item.is_active) }}
              </b-badge>
            </template>

            <template #cell(active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.active === 1 ? 'success' : 'danger'"
                style="cursor: auto;"
              >
                {{ resolveActiveUser(data.item.active) }}
              </b-badge>
            </template>

            <template #cell(document)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="Download">
                    <a
                      :href="data.item.doc.url"
                      target="_blank"
                    >
                      <feather-icon
                        icon="DownloadCloudIcon"
                        class="cursor-pointer"
                      />
                    </a>
                  </span>
                </div>
              </slot>
            </template>

          </b-table>

        </b-col>

        <!-- //////////////////////////// pagination //////////////////////////// -->
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-between "
          >
            <div>
              Results: <span class="font-weight-bold">{{ totalRows }}</span>
            </div>
            <!-- pagination -->
            <b-pagination
              :value="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="end"
              first-number
              last-number
              :total-visible="7"
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="getAllData($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import handleFormData from '@/global-compositions/handleFormData'
import BulkActions from './BulkActions.vue'
import table from './table'
import decryptData from '@/store/auth/decryptData'
import QrCode from './QrCode.vue'
import QRCodeDevice from './QRCodeDevices.vue'

export default {
  components: {
    BulkActions,
    QrCode,
    QRCodeDevice,
    'v-select': VSelect,
  },
  props: {
    apiUrl: {
      type: String,
      default: () => '',
    },
    addType: {
      type: String,
      default: () => '',
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    typeModel: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    viewContent: {
      type: Boolean,
      default: () => true,
    },
    editContent: {
      type: Boolean,
      default: () => true,
    },
    PostContent: {
      type: Boolean,
      default: () => false,
    },
    deleteContent: {
      type: Boolean,
      default: () => true,
    },
    CancelContent: {
      type: Boolean,
      default: () => false,
    },
    blockContent: {
      type: Boolean,
      default: () => true,
    },

    searchBox: {
      type: Boolean,
      default: () => true,
    },
    selectable: {
      type: Boolean,
      default: () => true,
    },
    guard: {
      type: String,
      default: () => '',
    },
    urlType: {
      type: String,
      default: () => '',
    },
    role: {
      type: [String, Number],
      default: () => '',
    },
    exportTable: {
      type: Boolean,
      default: () => true,
    },
    certificateOptions: {
      type: Array,
      default: () => [],
    },
    selectcertificate: {
      type: Boolean,
      default: () => false,
    },
    selectSubcertificate: {
      type: Boolean,
      default: () => false,
    },
    subCertificateOptions: {
      type: Array,
      default: () => [],
    },

    // perPage: {
    //   type: [String, Number],
    //   default: () => '',
    // },
  },
  data() {
    return {
      rows: [],
      totalRows: null,
      isBusy: false,
      currentPage: 1,
      popoverShow: false,
      loader: false,
      currentTime: '',
      showEmpty: 'loading...',
      modes: ['multi', 'single', 'range'],
      selectMode: 'multi',
      selected: [],
      page: 1,
      sortBy: '',
      dataKey: '',
      sortDesc: '',
      loadExport: false,
      dataLoad: 'false',
      tableDataKey: 0,
      showInfoPopup: false,
      showCompanyPopup: false,
      showProductPopup: false,
      infoDescription: '',
      infoDescriptionAr: '',
      infoDescriptionEn: '',
      infoId: '',
      infoTitle: '',
      infoTitleAr: '',
      infoTitleEn: '',
      CompanyInfoDescription: '',
      CompanyInfoId: '',
      CompanyInfoName: '',
      CompanyInfoEmail: '',
      CompanyInfoPhone: '',
      selectedSearchType: this.$route.query.type,
      searchCodeQuery: '',
      perPage: 20,
      searchQuery: this.$route.query.search,
      exportedData: [],
      getIdCertificate: null,
      showNameCertificate: null,
      getIdSubCertificate: null,
      showNameSubCertificate: null,
      localSubCertificateOptions: [],
    }
  },
  setup() {
    const { moduleStatus } = table()
    const resolveActiveUser = role => {
      if (role === 1) return 'Active'

      return 'Not Active'
    }
    const { formData, setFormData } = handleFormData()
    const { skin } = useAppConfig()
    const resolveActive = role => {
      if (role === '1') return 'Active'

      return 'Not Active'
    }
    const resolveJobStatus = role => {
      if (role === 0) return 'Admin'
      if (role === 1) return 'Merchant'

      return 'Customer'
    }

    const resolveActiveOrFalse = role => {
      // eslint-disable-next-line eqeqeq
      if (role == 'true') return 'Active'

      return 'Not Active'
    }

    const perPageOptions = [10, 25, 50, 100]

    return {
      resolveActiveUser,
      resolveActive,
      resolveActiveOrFalse,
      resolveJobStatus,
      skin,
      formData,
      setFormData,
      avatarText,
      perPageOptions,
      moduleStatus,
    }
  },
  /* eslint-disable eqeqeq */
  watch: {
    '$route.query': {
      handler(newQuery) {
        // Handle page change
        const newPage = newQuery.page || 1
        if (newPage !== this.page) {
          this.page = newPage
          this.getAllData(newPage)
        }

        // Handle search query change
        const newSearch = newQuery.search || ''
        if (newSearch !== this.searchQuery) {
          this.searchQuery = newSearch
          this.getAllData(newPage) // Pass the current page to maintain context
        }
      },
      immediate: true, // Ensures the watcher triggers on component mount
      deep: true, // Watches for deep changes in the query object
    },

    showNameCertificate(newValue) {
      if (newValue === null) {
        this.clearCertificateSelection()
      }
    },

  },
  mounted() {
    this.getAllData(this.page)
    this.getRouting()
    this.getCurrentDateTime()
    // console.log(this.getCertificate)

    if (this.selectSubcertificate === true) {
      axios
        .get('certificate/get-sub')
        .then(res => {
          const dataSubCertificate = decryptData(res.data.payload)?.data?.groups
          this.localSubCertificateOptions = dataSubCertificate.map(item => ({
            value: item.id,
            text: `${item.name?.ar} - ${item.name?.en}`,
          }))
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    onRowSelected(items) {
      const rows = []
      // eslint-disable-next-line array-callback-return
      items.map(item => {
        rows.push(item.id)
      })
      this.selected = rows
    },
    selectAllRows(rows) {
      if (rows) {
        this.$refs.productsTable.selectAllRows()
      } else {
        this.$refs.productsTable.clearSelected()
      }
    },
    onClose() {
      this.popoverShow = false
    },
    showPopover() {
      this.popoverShow = true
    },
    changeStatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (data.active === false) {
            this.setFormData(data)
            this.formData.append('activation_at', this.currentTime)
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal('Changed!', 'Changed Successfully.', 'success')
                this.getAllData()
              }
            })
          } else {
            this.setFormData(data)
            this.formData.append('activation_at', '')
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal('Changed!', 'Changed Successfully.', 'success')
                this.getAllData()
              }
            })
          }
        }
      })
    },
    changeActivetatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.setFormData(data)
          const newStatus = data.active === 0 ? 1 : 0
          this.formData.append('active', newStatus)
          if (data.expire_at) {
            this.formData.append('expire_at', data.expire_at.slice(0, 10))
          }
          this.formData.append('_method', 'PUT')
          axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
            if (res.status === 200) {
              this.$swal('Changed!', 'Changed Successfully.', 'success')
              this.getAllData()
            }
          })
        }
      })
    },
    editToPage(id, name) {
      if (this.type === 'page') {
        this.$router.push({ name: this.editComponent, params: { id, name } })
      } else {
        this.$parent.$refs.genmodal.show(id)
        this.$store.commit('generalIds/SET_ID', id)
      }
    },
    viewmodel(id, isBlocked) {
      if (isBlocked === 1) {
        this.$parent.$refs['block-modal'].show(id)
      } else {
        this.$parent.$refs['unblock-modal'].show(id)
      }
      this.$store.commit('generalIds/SET_ID', id)
    },
    viewPage(data) {
      if (this.viwComponent) {
        this.$router.push({ name: this.viwComponent, params: { id: data.id } })
        this.$store.commit('generalIds/SET_ID', data.id)
      } else {
        this.$parent.$refs['view-modal'].show()
        this.$store.commit('generalIds/SET_ID', data.id)
      }
    },
    goToPageOrOpenModal() {
      if (this.type === 'page') {
        this.$router.push({ name: this.addComponentName })
      } else {
        this.$parent.$refs.genmodal.show()
      }
    },
    roleType() {
      if (this.$route.name === 'users') {
        return '0'
      }
      if (this.$route.name === 'merchant-users') {
        return '1'
      }
      if (this.$route.name === 'customer-users') {
        return '2'
      }
      return ''
    },
    async getAllData(page = 1) {
      this.dataLoad = true
      try {
        const skip = (page - 1) * this.perPage
        const response = await axios.get(this.apiUrl, {
          params: {
            skip,
            search: this.searchQuery,
            certificate: this.getIdCertificate,
            sub: this.getIdSubCertificate,
          },
        })
        if (response.status === 200) {
          const responseData = decryptData(response.data.payload)
          // console.log(responseData)

          this.rows = responseData?.data[Object.keys(responseData?.data)[0]]
          this.totalRows = responseData.data.count || responseData.data.length
        }
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.dataLoad = false
      }
    },

    getName(name) {
      if (typeof name === 'object' && name.en) {
        return name.en
      }
      return name
    },
    deleteRow(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(`${this.apiUrl}/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    CancelEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/cancel`)
            .then(() => {
              this.$swal('Cancel!', 'Cancel Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    ApprovalDevies(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to Approval this employee!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approval',
        cancelButtonText: 'Disapproval',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .patch(`${this.apiUrl}/${id}`, {
              approved: 1,
            })
            .then(() => {
              this.$swal('Approved!', 'Approve Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        } else if (result.dismiss === 'cancel') {
          axios
            .patch(`${this.apiUrl}/${id}`, {
              approved: 0,
            })
            .then(() => {
              this.$swal('Disapproved!', 'Disapprove Successfully.', 'danger')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    async searchTable() {
      if (this.searchQuery === '') {
        this.getAllData()
      }
      const response = await axios.get(
        `${this.apiUrl}?search=${this.searchQuery}&page=${this.$route.query
          ?.page || 1}`,
      )
      this.$router
        .push({ query: { ...this.$route.query, search: this.searchQuery } })
        .catch(() => {})
      this.rows = response.data?.data
      this.totalRows = response.data.count
      this.perPage = 20
      this.page = response.data.meta?.per_page
    },

    async searchCertificateTable(selectedOption) {
      if (!selectedOption) {
        this.getIdCertificate = null
        this.showNameCertificate = null
      } else {
        this.getIdCertificate = selectedOption.value
        this.showNameCertificate = selectedOption.text

        try {
          const response = await axios.get(`certificate/${selectedOption.value}`)
          const data = decryptData(response.data.payload)?.data?.certificates
          // console.log(data)
          // Map data to update subCertificateOptions
          this.localSubCertificateOptions = data.map(item => ({
            value: item.id,
            text: `${item.name?.ar} - ${item.name?.en}`,
          }))
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error}`,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }

      await this.getAllData()
    },

    async searchSubCertificateTable(selectedOption) {
      if (!selectedOption) {
        this.getIdSubCertificate = null
        this.showNameSubCertificate = null
      } else {
        this.getIdSubCertificate = selectedOption.value
        this.showNameSubCertificate = selectedOption.text
      }

      // Refresh the data in the table using getAllData
      await this.getAllData()
    },
    async clearCertificateSelection() {
      this.getIdCertificate = null
      this.showNameCertificate = null
      this.getIdSubCertificate = null
      this.showNameSubCertificate = null
      try {
        const response = await axios.get('certificate/get-sub') // Adjust to the endpoint for all sub-certificates
        const data = decryptData(response.data.payload)?.data?.groups

        this.localSubCertificateOptions = data.map(item => ({
          value: item.id,
          text: `${item.name?.ar} - ${item.name?.en}`,
        }))
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }

      // Refresh the table or take other actions as needed
      await this.getAllData()
    },
    async clearSubCertificateSelection() {
      this.getIdSubCertificate = null
      this.showNameSubCertificate = null
      // Refresh the table or take other actions as needed
      await this.getAllData()
    },

    getRouting() {
      if (this.$route.query.search) {
        this.searchQuery = this.$route.query?.search
        this.searchTable()
      }
    },

    PostEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Post it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/post`)
            .then(() => {
              this.$swal('Posted!', 'Posted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getCurrentDateTime() {
      const today = new Date()

      const date = `${today.getFullYear()}-${today.getMonth()
        + 1}-${today.getDate()}`

      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

      const dateTime = `${date} ${time}`

      this.currentTime = dateTime
    },
    bulkDelete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .post(`${this.apiUrl}/bulk-destroy`, {
              ids: this.selected,
            })
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    showInfo(data) {
      this.showInfoPopup = true
      this.infoDescription = data.description
      this.infoDescriptionAr = data.description_ar
      this.infoDescriptionEn = data.description_en
      this.infoId = data.id
      this.infoTitle = data.title
      this.infoTitleAr = data.title_ar
      this.infoTitleEn = data.title_en
    },
    showCompany(data) {
      this.showCompanyPopup = true
      this.CompanyInfoDescription = data.description
      this.CompanyInfoId = data.id
      this.CompanyInfoName = data.name
      this.CompanyInfoEmail = data.email
      this.CompanyInfoPhone = data.phone
    },
  },
}
</script>

<style lang="scss">
.table-responsive::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color on hover */
}
.table-responsive .b-table tbody tr:hover {
  background-color: #297dd6;
  color: white ;
}
.padd span {
  padding: 2px;
}
td {
  padding: 0px !important;
  text-align: center !important;
  text-wrap: nowrap !important;
}
.table-responsive {
  position: relative;
  overflow: auto;
  max-height: 600px;
}
table thead {
  position: sticky !important;
  top: 0;
  background: white;
  z-index: 3;
}
#table-spinner {
  position: absolute;
  right: 50%;
  top: 5rem;
  z-index: 999999999;
}
.change-status {
  cursor: pointer;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
